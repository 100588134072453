<template>
	<div>
		<v-dialog v-model="dialog" width="600">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="error" v-bind="attrs" v-on="on">
					<v-icon> mdi-reply </v-icon>
				</v-btn>
			</template>
			<v-card color="background">
				<v-card-title>
					<v-avatar size="32" class="mr-2">
						<v-img :src="user.avatarUrl" alt="avatar" />
					</v-avatar>
					<span
						>What do you want to do to <b>{{ fullName }}</b
						>'s comment</span
					>
				</v-card-title>
				<v-card-text class="pb-0 pt-1">
					<v-form ref="form" lazy-validation>
						<v-select
							v-model="action"
							:items="actionItems"
							label="Choose an action"
							:rules="required"
							outlined
							dense
						></v-select>
						<v-textarea
							v-model="reason"
							label="Reason"
							outlined
							:rules="required"
							autoGrow
						></v-textarea>
					</v-form>
				</v-card-text>
				<v-card-actions class="pb-4 pt-0">
					<span v-if="action" class="tip">
						{{ action.tip }}
					</span>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="submit"> Submit </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'AdminUserActionButton',
	props: {
		user: {
			type: Object,
		},
		report: {
			type: Object,
		},
	},
	data: () => ({
		dialog: false,
		action: undefined,
		reason: undefined,
		actionItems: [
			{
				text: 'Ignore Report',
				value: {
					id: 'ignore',
					tip: 'Ignore this report. Does not message user',
				},
			},
			{
				text: 'Request More Info',
				value: {
					id: 'info',
					tip: 'Ignore this report and requests more info',
				},
			},
			{
				text: 'Send Warning',
				value: {
					id: 'warn',
					tip: 'Sends a warning to the user',
				},
			},
			{
				text: 'Send Message',
				value: {
					id: 'message',
					tip: 'Sends a message to the user',
				},
			},
			{
				text: 'Leave Comment (No Msg)',
				value: {
					id: 'comment',
					tip: 'Leave a comment on this report',
				},
			},
		],
		required: [(v) => !!v || 'Cannot be empty'],
	}),
	computed: {
		fullName() {
			return (
				this.user.fullName || this.user.username + '#' + this.user.discriminator
			);
		},
	},
	methods: {
		async submit() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const opt = {
				text: 'Are you sure you want to do this?',
				width: 400,
				bottomText: `<b>User:</b> ${this.fullName}<br><b>Action:</b> ${this.action.tip}<br><b>Reason:</b> ${this.reason}`,
				buttons: [
					{
						text: 'Nvm',
						color: 'error',
						returns: false,
					},
					{
						text: 'yes',
						color: 'primary',
						returns: true,
					},
				],
			};
			const yes = await this.$modal(opt).showWarn();
			if (yes) this.handleAction();
		},
		async handleAction() {
			try {
				switch (this.action.id) {
					case 'ignore':
						await this.$store.dispatch('ignoreComment', {
							id: this.user.discordId || this.user.id,
							reason: this.reason,
							reportId: this.report._id,
						});
						break;
					case 'info':
						await this.$store.dispatch('requestInfo', {
							id: this.user.discordId || this.user.id,
							reason: this.reason,
							reportId: this.report._id,
						});
						break;
					case 'warn':
						await this.$store.dispatch('warnComment', {
							id: this.user.discordId || this.user.id,
							reason: this.reason,
							reportId: this.report._id,
						});
						break;
					case 'message':
						await this.$store.dispatch('messageComment', {
							id: this.user.discordId || this.user.id,
							reason: this.reason,
							reportId: this.report._id,
						});
						break;
					case 'comment':
						await this.$store.dispatch('leaveCommentComment', {
							id: this.user.discordId || this.user.id,
							reason: this.reason,
							reportId: this.report._id,
						});
						break;
				}
			} catch (err) {
				console.error(err);
				const opt = {
					text: 'Action failed. Please contact Scoot.',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			const opt = {
				text: 'Success!',
				width: 400,
				bottomText: `<b>User:</b> ${this.fullName}<br><b>Action:</b> ${this.action.tip}<br><b>Reason:</b> ${this.reason}`,
			};
			await this.$modal(opt).showInfo();
			this.reason = undefined;
			this.action = undefined;
			this.dialog = false;
			this.$emit('refresh');
		},
	},
};
</script>

<style scoped>
.tip {
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}
</style>
